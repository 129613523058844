import {useEffect, useState} from 'react';
import "./About.css";
import { Link } from 'react-router-dom';
export const About = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [dvr, setDvr] = useState(true);
    const [app, setApp] = useState(false);
    const [roku, setRoku] = useState(false);

    const handleDvr = () => {
        setDvr(true);
        setApp(false);
        setRoku(false);
    }
    const handleApp = () => {
        setDvr(false);
        setApp(true);
        setRoku(false);
    }
    const handleRoku = () => {
        setDvr(false);
        setApp(false);
        setRoku(true);
    }
    useEffect(() => {
      setIsLoggedIn(localStorage.getItem('authorizationToken') ? true : false);
    }
    , []);
    return (
        <main id="main-content" role="main">
      

    
        <div className="inner-column xoc">
          <h2 className="title">Your on‑campus, TV‑streaming service</h2>

          <div className="media-container">
            <div className="media">
              
                <picture>
                  <source media="(min-width: 768px)" srcSet="/library/images/about/About-Intro-Image-480.jpg, /library/images/about/About-Intro-Image-960.jpg 2x" />
                  <source srcSet="/library/images/about/m_About-Intro-Image-767.jpg, /library/images/about/m_About-Intro-Image-1534.jpg 2x" />
                  <img src="/library/images/about/m_About-Intro-Image-767.jpg" alt="Xfinity On Campus™ on a tablet" data-cookiecategory="1210" />
                </picture>
              

              
            </div>

            <div className="copy"><p>Stream live TV and thousands of Xfinity On Demand shows and movies directly to your laptop, desktop, and mobile devices. Including Apple, Android, and Kindle, all while staying connected to your campus network.</p> <p>Xfinity On Campus is designed for students living in on-campus housing, and is included as part of the students' housing fees. Additionally, you can use your school credentials to login to over 90 "TV Everywhere" apps when off-campus.</p><p>Enjoy all the essential networks and shows, or add premium channels and sports packages with no minimum term contract required</p></div>
          </div>
        </div>
        <div className="steps-banner">

          <h2>Start streaming</h2>

          <ul className="inner-column">
              <li className="desktop">
                <div className="icon icon-laptop"></div>
                <p className="step-num">1.</p>
                <div className="step-desc">Visit</div>
                <div className="ctas">
                  <Link to={isLoggedIn? '/home' : '/login'} className="xoc-btn">
                    www.xfinityoncampus.com
                  </Link>
                </div>
              </li>
              <li className="desktop">
                <div className="icon icon-search"></div>
                <p className="step-num">2.</p>
                <div className="step-desc">Find your school</div>
              </li>
              <li className="desktop">
                <div className="icon icon-lock"></div>
                <p className="step-num">3.</p>
                <div className="step-desc">Sign in using your school credentials</div>
              </li>
              <li className="desktop">
                <div className="icon icon-tv"></div>
                <p className="step-num">4.</p>
                <div className="step-desc">Once authenticated, start watching</div>
              </li>
              <li className="mobile">
                <div className="icon icon-download"></div>
                <p className="step-num">1.</p>
                <div className="step-desc">Download the “Xfinity Stream” app from the App store</div>
                <div className="ctas">
                  <a target="_blank" href="https://itunes.apple.com/us/app/xfinity-stream/id731629156?mt=8" className="app-store btn-primary show" rel="noreferrer">
                    <span className="sr-only">Follow link to</span> App Store
                  </a>
                  <a target="_blank" href="https://play.google.com/store/apps/details?id=com.xfinity.cloudtvr&amp;hl=en" className="play-store btn-primary show" rel="noreferrer">
                    <span className="sr-only">Follow link to</span> Play Store
                  </a>
                  <a target="_blank" href="https://amzn.com/B01DUQOSRQ" className="kindle-store btn-primary show" rel="noreferrer">
                    <span className="sr-only">Follow link to</span> Amazon Kindle
                  </a>
                </div>
              </li>
              <li className="mobile">
                <div className="icon icon-device"></div>
                <p className="step-num">2.</p>
                <div className="step-desc">Select “Xfinity On Campus Students — Sign in Here”</div>
              </li>
              <li className="mobile">
                <div className="icon icon-search"></div>
                <p className="step-num">3.</p>
                <div className="step-desc">Find your school</div>
              </li>
              <li className="mobile">
                <div className="icon icon-lock"></div>
                <p className="step-num">4.</p>
                <div className="step-desc">Sign in using your school credentials</div>
              </li>
              <li className="mobile">
                <div className="icon icon-play"></div>
                <p className="step-num">5.</p>
                <div className="step-desc">Once authenticated, you will return to the 'Xfinity Stream' app</div>
              </li>
          </ul>
        </div>
        <section className="tabs tabbed-accordion">
      <div className="inner-column">
          <ul className="tabs__list" role="tablist">
          <li role="presentation">
              <button onClick={handleDvr} data-tab="0" role="tab" aria-controls="panel-0" aria-selected={dvr ? "true" : "false"}>
              <span className="name">DVR</span>
              </button>
          </li>
          <li role="presentation">
              <button onClick={handleApp} data-tab="1" role="tab" aria-controls="panel-1" aria-selected={app ? "true" : "false"}>
              <span className="name">APP</span>
              </button>
          </li>
          <li role="presentation">
              <button onClick={handleRoku} data-tab="2" role="tab" aria-controls="panel-2" aria-selected={roku ? "true" : "false"}>
              <span className="name">ROKU</span>
              </button>
          </li>
          </ul>
          <div className="tabs__tab-content dvr">
          <div className="title active" data-title="0">
              <h2 className="name">DVR</h2>
              {/* eslint-disable-next-line jsx-a11y/role-supports-aria-props */}
              <button data-tab="0" aria-controls="panel-0" aria-selected={dvr ? "true" : "false"} onClick={handleDvr}>
              <span className="show-tab" >
                  Show
                  <span className="sr-only">dvr description</span>
              </span>
              </button>
          </div>
          <section className="tab-item-text" tabIndex={0} data-panel="0" role="tabpanel" aria-labelledby="tab-0" aria-hidden={!dvr ? "true" : "false"}>
              <h3>Work around your finals schedule</h3>
              <picture className="bg-img">
              <source srcSet="/library/images/tabbed/Tab-Module-DVR-1x.jpg, /library/images/tabbed/Tab-Module-DVR-2x.jpg 2x"/>
              <img src="/library/images/tabbed/Tab-Module-DVR-1x.jpg" alt="The Xfinity Stream DVR" data-cookiecategory="1210"/>
              </picture>
              <div className="content">
                  <p>Manage your DVR from any device to ensure you'll never miss a thing.</p>
                  <div className="features">
                      <div className="feature">
                          <div className="img-wrapper">
                          <img className="icon target" src="/library/images/svg/icon-target.svg" data-cookiecategory="1210" alt=""/>
                          </div>
                      <p>Record and watch two shows at once</p>
                      </div>
                      <div className="feature">
                          <div className="img-wrapper">
                          <img className="icon upload" src="/library/images/svg/icon-upload.svg" data-cookiecategory="1210" alt=""/>
                          </div>
                      <p>20 hours of recording storage</p>
                      </div>
                      <div className="feature">
                          <div className="img-wrapper">
                          <img className="icon play" src="/library/images/svg/icon-play-small.svg" data-cookiecategory="1210" alt=""/>
                          </div>
                      <p>Download and watch even when you're offline</p>
                      </div>
                  </div>
              </div>

          </section>
          </div>
          <div className="tabs__tab-content app">
              <div className="title" data-title="1">
                  <h2 className="name">APP</h2>
                  <button data-tab="1" aria-controls="panel-1" aria-selected={app ? "true" : "false"}  onClick={handleApp} >
                  <span className="show-tab">
                      Show
                      <span className="sr-only">app description</span>
                  </span>
                  </button>
              </div>
              <section className="tab-item-text" tabIndex={0} data-panel="1" role="tabpanel" aria-labelledby="tab-1" aria-hidden={!app ? "true" : "false"}>
                  <h3>Lose the dorms, not your shows.</h3>
                  <picture className="bg-img">
                  <source srcSet="/library/images/tabbed/Tab-Module-App-1x.jpg, /library/images/tabbed/Tab-Module-App-2x.jpg 2x"/>
                  <img src="/library/images/tabbed/Tab-Module-App-1x.jpg" alt="The Xfinity Stream App" data-cookiecategory="1210"/>
                  </picture>
                  <div className="content">
                  <p>Download the Xfinity Stream app to watch everything on your mobile devices, as well as record and download to watch even when you're offline.</p>
                  <div className="features">
                      <div className="feature">
                          <a className="img-wrapper" href="https://itunes.apple.com/us/app/xfinity-stream/id731629156?mt=8"  target='_blank'  rel="noreferrer">
                          <picture className="icon apple">
                              <source srcSet="/library/images/tabbed/app-store-badge-1x.png, /library/images/tabbed/app-store-badge-2x.png 2x"/>
                              <img src="/library/images/tabbed/app-store-badge-1x.png" alt="Download on the App Store" data-cookiecategory="1210"/>
                          </picture>
                          </a>
                      </div>
                      <div className="feature">
                          <a className="img-wrapper" href="https://play.google.com/store/apps/details?id=com.xfinity.cloudtvr&amp;hl=en" target='_blank'  rel="noreferrer">
                          <picture className="icon android">
                              <source srcSet="/library/images/tabbed/google-play-badge-1x.png, /library/images/tabbed/google-play-badge-2x.png 2x"/>
                              <img src="/library/images/tabbed/google-play-badge-1x.png" alt="Download with Google Play" data-cookiecategory="1210"/>
                          </picture>
                          </a>
                      </div>
                      <div className="feature">
                          <a className="img-wrapper" href="https://amzn.com/B01DUQOSRQ" target='_blank'  rel="noreferrer">
                          <picture className="icon amazon">
                              <source srcSet="/library/images/tabbed/amazon-badge-1x.png, /library/images/tabbed/amazon-badge-2x.png 2x"/>
                              <img src="/library/images/tabbed/amazon-badge-1x.png" alt="Download onto your Amazon Kindle" data-cookiecategory="1210"/>
                          </picture>
                          </a>
                      </div>
                  </div>
                  </div>
              </section>
          </div>

          <div className="tabs__tab-content roku">
              <div className="title" data-title="2">
                  <h2 className="name">ROKU</h2>
                  {/* eslint-disable-next-line jsx-a11y/role-supports-aria-props */}
                  <button data-tab="2" aria-controls="panel-2" aria-selected={roku ? "true" : "false"} onClick={handleRoku} >
                  <span className="show-tab" >
                      Show
                      <span className="sr-only">roku description</span>
                  </span>
                  </button>
              </div>
              <section className="tab-item-text" tabIndex={0} data-panel="2" role="tabpanel" aria-labelledby="tab-2" aria-hidden={!roku ? "true" : "false"}>
                  <h3>Give your shows the screen size they deserve.</h3>
                  <picture className="bg-img">
                  <source srcSet="/library/images/tabbed/Tab-Module-ROKU-1x.jpg, /library/images/tabbed/Tab-Module-ROKU-2x.jpg 2x"/>
                  <img src="/library/images/tabbed/Tab-Module-ROKU-1x.jpg" alt="Xfinity Stream on Roku" data-cookiecategory="1210"/>
                  </picture>
                  <div className="content">
                  <p>Search for Xfinity Stream on your Roku device to watch TV on a bigger screen.</p>
                  </div>
              </section>
          </div>
      </div>
      </section>

        <div className="about-banner">

          <h2>Don't have Xfinity On Campus at your school?</h2>

          <p>Talk to your school's administration and ask to have TV on your terms. Visit <a href="https://www.xfinity.com/xfinitycommunities" target="_blank" style={{textDecoration: "underline !important"}} rel="noreferrer">Xfinity Communities</a> for more information.</p>

        </div>
    </main>
    );
}