import React, { useState, useEffect } from 'react';

import "./Picker.css";
import "./jquery-ui.css";

type OptionType = {
    // Define the properties of OptionType here
    id: string;
    value: string;
    label: string;
};

export const Picker = () => {
    const [options, setOptions] = useState<OptionType[]>([]);
    const [filteredOptions, setFilteredOptions] = useState(options);
    const [searchTerm, setSearchTerm] = useState('');
    let continueUrl = window.location.protocol+ "//" + window.location.hostname;
    if(window.location.hostname === 'localhost'){
        continueUrl = window.location.protocol+ "//" + window.location.hostname +":"+window.location.port;
    }
    useEffect(() => {
        const queryParameters = new URLSearchParams(window.location.search);
        let tenant = queryParameters.get('tenant') as string;
        let continueTenantUrl = queryParameters.get('continue') as string ;
        fetch("/api/login?group="+tenant+"&storefrontUrl="+encodeURIComponent(continueUrl))
            .then(response => response.json())
            .then(data => {
                let partnerList = data.partnerList;
                console.log('data:', partnerList)
                setOptions(partnerList.map((item: any) => ({
                    id: item.code,
                    value: item.authTenantUrl + "&tenant=" + tenant + "&continue=" + encodeURIComponent(continueTenantUrl),
                    label: item.name
                    }))
                );
                setFilteredOptions(options);
             });
            },[continueUrl]);
            useEffect(() => {
                const inputElement = document.getElementById('picker-input');
                const handleInputChange = (event: Event) => {
                  const target = event.target as HTMLInputElement;
                  setSearchTerm(target.value);
                };
            
                if (inputElement) {
                    inputElement.addEventListener('input', handleInputChange);
                }
            
                return () => {
                  if (inputElement) {
                    inputElement.removeEventListener('input', handleInputChange);
                  }
                };
              }, []);
            
              useEffect(() => {
                const filtered = options.filter(option =>
                  option.label.toLowerCase().includes(searchTerm.toLowerCase())
                );
                setFilteredOptions(filtered);
              }, [searchTerm, options]);
        return (
            <div className="picker-container">
                <div id="mainpanel">
                    <div>
                        <h1 id="pickerheader">XFINITY On Campus</h1>
                    </div>
                    <section id="sign-in-r1" className="wrap">
                        <article>
                            <div className="search-container">
                                <form action="" id="picker-form">
                                    <label htmlFor="picker-input" className="screen-reader-text" style={{ display: 'none' }}>Search for Colleges</label>
                                    <input id="picker-input" type="search" className="picker-search-web" placeholder="&nbsp;&#xf002;&nbsp;Search" aria-label="Search School Name" />
                                </form>
                            </div>
                        </article>
                    </section>
                    <div style={{height:"800px"}}>
                        <ul className='ui-autocomplete ui-front ui-menu ui-widget ui-widget-content ui-resizable' style={{ display: 'block', maxHeight: '400px', width: '375px' }}>
                                    {filteredOptions.map((option) => (
                                        <li id={option.id} className='ui-menu-item'>
                                            <a href="#" onClick={() => { window.location.href = option.value; }} className="ui-link" aria-label={option.label} aria-describedby={option.label}>{option.label}</a>
                                        </li>
                                    ))}
                                </ul>
                    </div>
                </div>
            </div>	
        );
    };
