import React, { useState, useEffect } from 'react';
import './Picker.css';
import { Link } from 'react-router-dom';
export const ErrorPicker = () => {
    const [errorCode, setErrorCode] = useState<string | null>(null);
    useEffect(() => {
        const queryParameters = new URLSearchParams(window.location.search);
        const code = queryParameters.get('errorcode');
        setErrorCode(code);
    }, []);
    return (
        <div className="errorContainer">
        <div className="errorsection">
				<span className="icon-badge-nowifi"></span>
				<hr className="errorsectionrule"/>
				<p className="errorsectiontext">
					<label 
                    className="errorsectiontextlabel" 
                    id="FEDX10001" 
                    style={{ display: errorCode === 'FEDX10001' ? 'block' : 'none' }}>
						<b>We are unable to determine your campus residency.</b><br/><br/>
						To access this streaming service your institution must indicate that your account is eligible for the service. Your account is currently not set properly or missing the required information.<br/><br/>						
						If you feel this is an error, please submit a help desk ticket to your institution's Information Technology department. Ask them to verify your account has the correct housing attribute to receive the service.<br/><br/>
						(Error code: FEDX10001)
					</label>
					<label 
                    className="errorsectiontextlabel" 
                    id="FEDX10002" 
                    style={{ display: errorCode === 'FEDX10002' ? 'block' : 'none' }}>
						<b>We cannot find a registration associated with this credential.</b><br/><br/>
						To access this streaming service, you must first register on the XFINITY On Campus portal.  Please click <Link to="/login" style={{color:"#d5e4ec", opacity: 0.7}}>here</Link>, log in with your university credential, and follow the directions on the website.<br/><br/>
						(Error code: FEDX10002)
					</label>
				
                </p>
		</div>
        </div>
    );
}