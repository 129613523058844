import 'jquery';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import '../account/csgiaccount.css';
import '../account/Order.css';
import '../checkout/Checkout.css';
import { jwtDecode } from 'jwt-decode';
import { Link } from 'react-router-dom';
declare global {
    interface Window {
        ascendon: any;
        asc: any; // Add this line to declare the 'asc' object
    }
}
export const RadarCheckout = () => {
    const logoutUrl = process.env.REACT_APP_LOGOUT_URL as string;
    const authToken = localStorage.getItem('authorizationToken') as string;
    const claims: any = jwtDecode(authToken);
    const sub = claims.sub;
    const csgSessionId = claims["session-id"]as string;
    const  fedxSessionId= claims["fedx-session-id"]as string;

    // const handleOrderComplete = () => {
    //         $('#thanks').on('show.bs.modal', function (e) {
    //             $('#thanks').css('display', 'block').addClass('in');
    //         });
    //         $('#thanks').modal('show');
    // };

    window.addEventListener('radar_order_completed', (params)=> {
        $('#thanks').on('show.bs.modal', function (e) {
            $('#thanks').css('display', 'block').addClass('in');
        });
        $('#thanks').modal('show');
    });


    window.addEventListener('radar_account_settings_session_expired', (params)=> {
        //handle session expiration
            if (logoutUrl) {
                window.location.href = logoutUrl;
            }
      });
    
    const goHome = () => {
        window.location.href = '/home';
    }
    return (
        <div id="upgradecontent">
            {/* <button className="btn btn-primary btn-lg"  type="button" onClick={handleOrderComplete}>TEST ORDER COMPLETE BOX</button> */}
            <div id="thanks"  className="modal" data-backdrop="static" style={{display:"none"}}>
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                                <button aria-hidden="true" className="close"  type="button" onClick={goHome}>×</button>
                            <h4 className="modal-title">You're all set!  Thank you for your purchase.</h4>
                        </div>
                            <div className="modal-body" id="modalBody"><b>Thank you for your purchase. An order confirmation will be sent to your email address shortly.</b>
                                <br/> <br/>Please note, if you purchased a video upgrade, it may take up to 1 hour for your channels to appear in your lineup. Additionally, we suggest clearing your browser cache. If you have any questions about your order or bill, please submit a ticket at <Link to="/help">http://support.xfinityoncampus.com</Link>
                                <br/> <br/>If you purchased a WiFi speed upgrade, it may take up to 24 hours for the upgrade to become available. If you have any questions about your WiFi upgrade, please visit <Link to="/help">http://support.xfinityoncampus.com</Link>
                            </div>
                        <div className="modal-footer">
                        <button className="btn btn-primary btn-lg" onClick={goHome} type="button">Continue</button>
                        </div>
                    </div>
                </div>
            </div>
            {/* Widget will be drawn in this container.  Note the ascendon css class, it is required. */}
            <div className="ascendon">
                <xoc-order-checkout widget-user="XOCStoreFront" external-reference={sub} csgi-session-id={csgSessionId} fedx-session-id={fedxSessionId}></xoc-order-checkout>
            </div>
            
        </div>
    );
};
