import { InternetServices } from "../internetServices/InternetServices";
import "./Wifi.css";
import { Link } from 'react-router-dom'; // or 'next/link' if using Next.js
export const Wifi = () => {
    return (
        <div id="main-content" role="main">
         <div className="dpskContainer" >
            <div className="dpskContainerContent">
                <span style={{paddingRight: "10px" }}>
                    <svg width="22" height="16" viewBox="0 0 22 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M10.9994 2.25011C14.5201 2.2419 17.8978 3.64266 20.3794 6.14011L21.6094 4.90011C18.7948 2.07579 14.9716 0.488281 10.9844 0.488281C6.9971 0.488281 3.1739 2.07579 0.359375 4.90011L1.60938 6.14011C4.09507 3.64386 7.47663 2.24658 10.9994 2.26011V2.25011ZM10.9544 6.14457C13.4473 6.14457 15.838 7.13592 17.5994 8.90011L18.8394 7.62011C16.7483 5.52861 13.9119 4.35359 10.9544 4.35359C7.99684 4.35359 5.16047 5.52861 3.06938 7.62011L4.30938 8.90011C6.07077 7.13592 8.46141 6.14457 10.9544 6.14457ZM14.9194 11.6201C13.8819 10.5777 12.4701 9.99428 10.9994 10.0001C9.52687 9.99007 8.11161 10.5699 7.06938 11.6101L5.82937 10.3701C7.19864 8.99918 9.05676 8.22887 10.9944 8.22887C12.932 8.22887 14.7901 8.99918 16.1594 10.3701L14.9194 11.6201ZM10.9861 15.5001C10.297 15.4982 9.7394 14.9392 9.73938 14.2501L9.74938 14.2401C9.75487 13.5537 10.3129 13.0001 10.9994 13.0001C11.6884 13.0056 12.243 13.5677 12.2394 14.2567C12.2357 14.9458 11.6751 15.5019 10.9861 15.5001Z" fill="#6138F5"></path>
                    </svg>
                </span>
                <span>
                    Get connected using your 
                    <Link to="/account?tab=wifi" className="wifiLink">WiFi info.</Link>
                </span>
            </div>
        </div>
            <section className="hero-component logged-in wifi-specific">
                <div style={{display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column"}}>
                    <img style={{width: "100%"}} alt="Xfinity on campus" src="library/images/oncampus_logo.png" data-cookiecategory="1210"/>
                </div>
                <h2>Explore Internet Speeds</h2>
            </section>
            <InternetServices/>
        </div>
    );
};