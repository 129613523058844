import { Link} from 'react-router-dom';
import "./SiteMap.css";
export const SiteMap = () => {
    return (
        <div className="main-content" role="main">
            <div className="inner-column sitemap">
                <h2>Site Map</h2>
                    <div className="section-block">
                        <div className="left">
                            <h3>Home</h3>
                            <ul className="links-section">
                                <li>
                                    <Link to="/home" target="_self">Xfinity On Campus</Link>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div className="section-block">

                        <div className="left">
                            <h3>Watch Content & Upgrades</h3>
                            <ul className="links-section">
                                <li>
                                    <a href="https://tv.xfinity.com/" target="_blank" rel="noreferrer">Watch Now on Xfinity Stream</a>
                                </li>
                                <li>
                                    <Link to="/channels" >My Channels</Link>
                                </li>
                                <li>
                                    <Link to="/upgrade" >Upgrade Channels</Link>
                                </li>
                                <li>
                                    <Link to="/wifi" >Upgrade Wi-Fi</Link>
                                </li>
                                <li>
                                    <Link to="/checkout" >Cart</Link>
                                </li>
                            </ul>
                        </div>

                        <div className="right">
                            <h3>Manage Account</h3>
                            <ul className="links-section">
                                <li>
                                    <Link to="/account" >Profile</Link>
                                </li>

                                <li>
                                    <Link to="/account?tab=payment" >Payment</Link>
                                </li>

                                <li>
                                    <Link to="/account?tab=orderHistory">Order History</Link>
                                </li>
                                
                                <li>
                                    <Link to="/account?tab=sub" >Subscrptions</Link>
                                </li>

                                <li>
                                    <Link to="/account?tab=wifi" >Wi-Fi Info.</Link>
                                </li>
                            </ul>
                        </div>

                    </div>

                    <div className="section-block">

                        <div className="left">
                            <h3>Help and Support</h3>
                            <ul className="links-section">
                                <li>
                                    <Link to="/help" >Help</Link>
                                </li>
                                <li>
                                    <Link to="/about" >About</Link>
                                </li>
                                <li>
                                    <a href="http://my.xfinity.com/terms/" target="_blank" rel="noreferrer">Terms of Service</a>
                                </li>
                                <li>
                                    <a href="http://www.xfinity.com/privacy/policy/" target="_blank" rel="noreferrer">Privacy Policy</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
        </div>
    )

};
