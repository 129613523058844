import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';
import "./Header.css";
import 'jquery';
const logoutUrl = process.env.REACT_APP_LOGOUT_URL as string;

export const  Header = () => {
    const [isLoggedIn, setIsLoggedIn] = useState(localStorage.getItem('authorizationToken') ? true : false);
    const extendSession = async () => {
        const logoutUrl = process.env.REACT_APP_LOGOUT_URL as string;
        console.log('Extending session');
        if (localStorage.getItem('authorizationToken') != null) {
          const authToken = localStorage.getItem('authorizationToken') as string;
          try {
            const response = await fetch('/api/extend/session', {
              method: 'GET',
              headers: {
                'Content-Type': 'application/json',
                'Authorization': authToken,
              },
            });
        
            if (!response.ok) {
              console.error('Network response was not ok:', response.status, response.statusText);
            //   window.location.href = logoutUrl;
            //   throw new Error('Network response was not ok');
                const expireTime = localStorage.getItem('expireTime')
            //   console.log("Checking checkForInactivity: ", expireTime)
                if(expireTime && Number(expireTime) < Date.now()){
                    setIsLoggedIn(false);
                    window.location.href = logoutUrl;
                }
            } else {
              const expireTime = Date.now() + 1000 * 60 * 5; // 5 minutes
              localStorage.setItem('expireTime', expireTime.toString());
            }
          } catch (error) {
            console.error('Error:', error);
            window.location.href = logoutUrl;
          }
        }
      };
      //function to for inactivity and logout
      const checkForInactivity = () =>{
        const expireTime = localStorage.getItem('expireTime')
       //  console.log("Checking checkForInactivity: ", expireTime)
        if(expireTime && Number(expireTime) < Date.now()){
            setIsLoggedIn(false);
           window.location.href = logoutUrl;
        }
      }
      // useEffect to ser intervel to check for inactivity
      const navigate = useNavigate();
      useEffect(() => {
        //check for inactivity
        const intervel = setInterval(()=>{
          console.log('Checking for inactivity');
            checkForInactivity();
        },60000);
        //Clear intervel on ummount
        return () => clearInterval(intervel);
        
      },[]);
      const handleNavigation = (path: string) => {
        console.log('Navigating to:', path);
        navigate(path);
      };
      // update expiry time on any user activity
      useEffect(() => {
        const handleClick = (event: MouseEvent) => {
        let target = event.target as HTMLElement;
          // Check if the target is not an 'a' tag
        if (target.tagName.toLowerCase() !== 'a') {
            // Find the closest ancestor that is an 'a' tag
            target = target.closest('a') as HTMLElement;
        }
        if (target) {
          const href = target.getAttribute('href');
          if (target.tagName === 'A' && href && (href.startsWith('/') || href.startsWith('#'))) {
            event.preventDefault(); // Stop default navigation
            extendSession();
            // Execute the onclick function if it exists
            const onclick = target.getAttribute('onclick');
            if (onclick) {
            // Create a new function from the onclick attribute and execute it
            new Function(onclick).call(target);
            }
            // Navigate to the href attribute
            if(!href.startsWith('#')){
                handleNavigation(href as string);
            }
          }else{
            if (href) {
                event.preventDefault();
                if (href.startsWith('https://fedsp')) {
                    window.location.href = href;
                } else {
                 window.open(href, '_blank');
                }
            }
          }
        }
        };
        // set Event Listners
        window.addEventListener('click',handleClick)
        // window.addEventListener('keypress',handleClick)
        // window.addEventListener('scroll',extendSession)
        // window.addEventListener('mousemove',updateExpireTime)
    
        // cleanup
        return ()=>{
          window.removeEventListener('click',handleClick)
          // window.removeEventListener('keypress',handleClick)
          // window.removeEventListener('scroll',extendSession)
          // window.removeEventListener('mousemove',updateExpireTime)
        }
    
    
      },[]);

    let currentHost = window.location.protocol+ "//" + window.location.hostname ;
    if(window.location.hostname === 'localhost'){
        currentHost = window.location.protocol+ "//" + window.location.hostname +":"+window.location.port;
    }
    
    console.log('isLoggedIn:', isLoggedIn)
    const [currentPage, setCurrentPage] = useState('home');
    let fedxUrl = process.env.REACT_APP_FEDX_URL;
    let partner = null;
    if(isLoggedIn){
        const authToken = localStorage.getItem('authorizationToken') as string;
        const claims: any = jwtDecode(authToken);
        partner = claims.partner;
    }
    const location = useLocation();
    useEffect(() => {
        const url = new URL(window.location.href);
        const cleanedPathname = url.pathname;
        setCurrentPage(cleanedPathname);
      }, [location.pathname]);

    const [isMenuOpen, setIsMenuOpen] = useState(false);
    useEffect(() => {
        const mediaQuery = window.matchMedia('(min-width: 899px)');
        setIsMenuOpen(mediaQuery.matches);

        const handler = (e: MediaQueryListEvent) => setIsMenuOpen(e.matches);
        mediaQuery.addEventListener('change', handler);

        return () => mediaQuery.removeEventListener('change', handler);
    }, []);

    const toggleMenu = () => {
        const mediaQuery = window.matchMedia('(min-width: 899px)');
        if (!mediaQuery.matches) setIsMenuOpen(!isMenuOpen);
    };

    const toggleMenuIcon = () => {
        const mediaQuery = window.matchMedia('(max-width: 898px)');
        if (mediaQuery.matches) setIsMenuOpen(false);
    };
  return (
    <header tabIndex={-1} className="auth">
        <nav role="navigation" aria-label="Main menu">
            
            <h1 tabIndex={-1}>
                <Link className="logo-header seo-logo" to={isLoggedIn? '/home' : '/login'} target="_self" tabIndex={-1} title="Xfinity by Comcast">
                    <picture tabIndex={-1}>
                        <source srcSet="/library/images/xfinity-logo-black-1x.png, /library/images/xfinity-logo-black-2x.png 2x" tabIndex={-1} />
                        <img src="/library/images/xfinity-logo-black-1x.png" alt="Go to Xfinity Website" data-cookiecategory="1210" tabIndex={-1}></img>
                    </picture>
                </Link>
            </h1>
            {isLoggedIn ? (
                <>
                {partner.logoUrl ?(
                    <h1 style={{marginLeft: "15px" , borderLeft: "1px solid gray",width: "130px"}} >
                    <picture style={{paddingLeft: "15px"}}>
                    <img src={ partner.logoUrl} alt="Partner Logo"/> 
                    </picture>
                </h1>
                ): null}
            <div id="menu-main" className="menu-main">
                <button className="menu-toggle" type="button" aria-expanded="false" aria-controls="menu" aria-label="Navigation menu" onClick={toggleMenu}>
                    <span className="sr-only">Main menu</span>
                </button>
                <ul style={{width: "auto", display: isMenuOpen ? "block" : "none"}}>
                <li style={{marginRight: "10px"}} className={currentPage === '/home' ? 'active' : ''}>
                <Link className="link" to="/home"onClick={() => {setCurrentPage('/home'); toggleMenu();}}>
                        <span>
                            Home 
                        </span>
                        </Link>
                    </li>
                    <li style={{marginRight: "10px"}} className={currentPage === '/account' ? 'active' : ''}>
                    <Link className="link" to="/account" onClick={() => {setCurrentPage('/account');  toggleMenu();}}>
                        <span>
                            Account 
                        </span>
                        </Link>
                    </li>
                    <li style={{marginRight: "10px"}} className={currentPage === '/help' ? 'active' : ''}>
                    <Link className="link" to="/help" onClick={() => {setCurrentPage('/help'); toggleMenu();}}>
                        <span>
                            Support 
                        </span>
                        </Link>
                    </li>
                    {partner.stream  ? (
                    <>
                    <li style={{marginRight: "10px"}} className={`${currentPage === '/channels' ? 'active ' : ''}hide-on-mobile`}>
                    <Link className="link" to="/channels" onClick={() => {setCurrentPage('/channels');  toggleMenu(); }}>
                        <span>
                            Channels 
                        </span>
                        </Link>
                    </li>
                    <li style={{marginRight: "10px"}} >
                        <Link className={currentPage === '/upgrade' ? 'button active' : 'button'} to="/upgrade" onClick={() => {setCurrentPage('/upgrade'); toggleMenu();}}>
                            <span>
                                Video Add-ons
                            </span>
                        </Link>
                    </li>
                    </>
                    ): null}
                    {/* {partner.managedWifi || partner.dpskWifi ? (
                    <>
                    <li style={{marginRight: "10px"}} className={currentPage === '/wifi' ? 'active hide-on-mobile hide-on-tablet hide-on-ipad' : 'hide-on-mobile hide-on-tablet hide-on-ipad'}>
                    <Link className="button" to="/wifi" onClick={() => {setCurrentPage('/wifi'); toggleMenu();}}>
                        <span>
                            Wifi Upgrade
                        </span>
                        </Link>
                    </li>
                    </>
                    ) : null} */}
                    {/* <li style={{marginRight: "10px"}} className={`${currentPage === '/checkout' ? 'active' : ''} hide-on-mobile hide-on-tablet hide-on-ipad `}>
                        <Link className="link" to="/checkout" onClick={() => {setCurrentPage('/checkout'); toggleMenu();}}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" className="bi bi-cart-fill" viewBox="0 0 16 16">
                                <path d="M0 1.5A.5.5 0 0 1 .5 1H2a.5.5 0 0 1 .485.379L2.89 3H14.5a.5.5 0 0 1 .491.592l-1.5 8A.5.5 0 0 1 13 12H4a.5.5 0 0 1-.491-.408L2.01 3.607 1.61 2H.5a.5.5 0 0 1-.5-.5M5 12a2 2 0 1 0 0 4 2 2 0 0 0 0-4m7 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4m-7 1a1 1 0 1 1 0 2 1 1 0 0 1 0-2m7 0a1 1 0 1 1 0 2 1 1 0 0 1 0-2"/>
                            </svg>
                        </Link>
                    </li> */}
                </ul>
            </div>
            {partner.managedWifi || partner.dpskWifi ? (
            <div className="link-right" style={{display: "flex"}}>
                    <Link to="/wifi"className="hide-laptop" onClick={toggleMenuIcon}>
                        <span >
                            <svg version="1.1" id="Icons" viewBox="0 0 32 32" width="24px" height="24px" fill="#000000">
                                <g strokeWidth="0"></g>
                                <g strokeLinecap="round" strokeLinejoin="round"></g>
                                <g >
                                    <circle style={{ fill: 'none', stroke: '#000000', strokeWidth: 2, strokeLinecap: 'round', strokeLinejoin: 'round', strokeMiterlimit: 10 }} cx="16" cy="23.5" r="2.5"></circle>
                                    <g>
                                        <path style={{ fill: 'none', stroke: '#000000', strokeWidth: 2, strokeLinecap: 'round', strokeLinejoin: 'round', strokeMiterlimit: 10 }} d="M21.6,18.5C20.2,17,18.2,16,16,16s-4.2,1-5.6,2.5"></path>
                                    </g>
                                    <g>
                                        <path style={{ fill: 'none', stroke: '#000000', strokeWidth: 2, strokeLinecap: 'round', strokeLinejoin: 'round', strokeMiterlimit: 10 }} d="M25.1,14.9C22.8,12.5,19.6,11,16,11s-6.8,1.5-9.1,3.9"></path>
                                    </g>
                                    <g>
                                        <path style={{ fill: 'none', stroke: '#000000', strokeWidth: 2, strokeLinecap: 'round', strokeLinejoin: 'round', strokeMiterlimit: 10 }} d="M28.6,11.4C25.4,8.1,21,6,16,6S6.6,8.1,3.4,11.4"></path>
                                    </g>
                                </g>
                            </svg>
                        </span>
                        </Link>
                        <Link to="/wifi" onClick={() => {setCurrentPage('/wifi'); }} className={currentPage === '/wifi' ? 'button active small-screen-hide': 'button small-screen-hide'}>
                            <span >
                                Manage WiFi
                            </span>
                        </Link>
                </div>
            ) : null}
                <div className="link-right" style={{display: "flex"}}>{/* style={{display: isMenuOpen ? "none" :"flex"}}*/}
                    <Link to="/checkout" onClick={toggleMenuIcon}>
                        <span className="hide-laptop">
                            <svg height="20px" version="1.1" viewBox="0 0 20 20" width="20px" ><title/><desc/><defs/><g fill="none" fill-rule="evenodd" id="Page-1" stroke="none" stroke-width="1"><g fill="#000000" id="Core" transform="translate(-212.000000, -422.000000)"><g id="shopping-cart" transform="translate(212.000000, 422.000000)"><path d="M6,16 C4.9,16 4,16.9 4,18 C4,19.1 4.9,20 6,20 C7.1,20 8,19.1 8,18 C8,16.9 7.1,16 6,16 L6,16 Z M0,0 L0,2 L2,2 L5.6,9.6 L4.2,12 C4.1,12.3 4,12.7 4,13 C4,14.1 4.9,15 6,15 L18,15 L18,13 L6.4,13 C6.3,13 6.2,12.9 6.2,12.8 L6.2,12.7 L7.1,11 L14.5,11 C15.3,11 15.9,10.6 16.2,10 L19.8,3.5 C20,3.3 20,3.2 20,3 C20,2.4 19.6,2 19,2 L4.2,2 L3.3,0 L0,0 L0,0 Z M16,16 C14.9,16 14,16.9 14,18 C14,19.1 14.9,20 16,20 C17.1,20 18,19.1 18,18 C18,16.9 17.1,16 16,16 L16,16 Z" id="Shape"/></g></g></g></svg>
                        </span>
                        <span className="small-screen-hide">
                            <svg width="25" height="25" fill="currentColor" className="bi bi-cart-fill" viewBox="0 0 16 16">
                                <path d="M0 1.5A.5.5 0 0 1 .5 1H2a.5.5 0 0 1 .485.379L2.89 3H14.5a.5.5 0 0 1 .491.592l-1.5 8A.5.5 0 0 1 13 12H4a.5.5 0 0 1-.491-.408L2.01 3.607 1.61 2H.5a.5.5 0 0 1-.5-.5M5 12a2 2 0 1 0 0 4 2 2 0 0 0 0-4m7 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4m-7 1a1 1 0 1 1 0 2 1 1 0 0 1 0-2m7 0a1 1 0 1 1 0 2 1 1 0 0 1 0-2"/>
                            </svg>
                        </span>
                    </Link>
                </div>
                <div className="link-right" >
                    <a href={fedxUrl + "/logout?continue="+currentHost+"/storefrontLogout&xoc-school-id="+partner.code} target="_self">
                        <span className="hide-laptop">
                            <svg fill="none" height="24" viewBox="0 0 24 24" width="24" >
                                <path d="M17 16L21 12M21 12L17 8M21 12L7 12M13 16V17C13 18.6569 11.6569 20 10 20H6C4.34315 20 3 18.6569 3 17V7C3 5.34315 4.34315 4 6 4H10C11.6569 4 13 5.34315 13 7V8" stroke="#374151" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                            </svg>
                        </span>
                        <span className="small-screen-hide">
                            Sign Out
                        </span>
                    </a>
                </div>
            </>
            ) : (
                <div className="link-right" style={{width: "auto"}}  >
                <Link to="/about"  >
                    <span className="hide-lg" >
                        What's <span aria-hidden="false" >XOC?</span>
                    </span>
                    <span className="hide-sm" >What's Xfinity On Campus?</span>
                </Link>
            </div>
            ) }
        </nav>
    </header>
  );
}