import "./Footer.css";
import { Link } from "react-router-dom";
type FooterProps = {
    note: string
    }
export const Footer = (props: FooterProps) => {
  return (
    <footer className="footer">
      {!localStorage.getItem('authorizationToken') ? (
        <div style={{ width: "100%", justifyContent: "center", alignItems: "center", display: "flex", marginTop: "16px", paddingBottom: "16px", backgroundColor: "#FFFFFF"}}>
          <div style={{justifyContent: "center", alignItems: "center", padding: "32px 24px 16px", width: "1280px", height: "148px", background: "#E3E0F4", borderRadius: "8px"}}>
            <div style={{textAlign: "center"}}>
            <svg width="28" height="18" viewBox="0 0 28 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fillRule="evenodd" clipRule="evenodd" d="M9.73329 0.000100003H17.92C22.745 -0.0220478 26.7915 3.63717 27.2533 8.4401C27.3967 10.9287 26.5061 13.3661 24.7921 15.1761C23.078 16.986 20.6927 18.0079 18.2 18.0001H0.666626V12.6668V8.10677C0.673687 5.94967 1.53737 3.88373 3.06767 2.36343C4.59797 0.843132 6.66953 -0.00698404 8.82663 0.000100003H9.73329ZM19.3333 9.0001C19.3333 10.0089 18.5095 10.8268 17.4933 10.8268C16.4771 10.8268 15.6533 10.0089 15.6533 9.0001C15.6533 7.99126 16.4771 7.17343 17.4933 7.17343C18.5095 7.17343 19.3333 7.99126 19.3333 9.0001ZM10.5066 10.8268C11.5228 10.8268 12.3466 10.0089 12.3466 9.0001C12.3466 7.99126 11.5228 7.17343 10.5066 7.17343C9.49042 7.17343 8.66663 7.99126 8.66663 9.0001C8.66663 10.0089 9.49042 10.8268 10.5066 10.8268Z" fill="#6138F5"></path>
            </svg>
            
            </div>
                  <div style={{textAlign: "center", fontStyle: "normal", fontWeight: 700, fontSize: "16px", lineHeight: "24px", marginBottom: "24px"}}>
              Sign in for chat support
            </div>
                  <div style={{fontWeight: 400, fontSize: "12px", lineHeight: "16px", textAlign: "center"}}>
            Contact your student help-desk for sign-in issues.
            </div>
          </div>
        </div>
      ) : null}
        <div id="footer" className="footer-wrapper">
          <img className="logo-footer" src="/library/images/comcast-color-logo.png" alt="Comcast logo" data-cookiecategory="1210"/>
          <ul>
            <li>
              <Link to="/about">
                About
              </Link>
            </li>
            
            <li>
              <a href="http://my.xfinity.com/terms/" target="_blank" rel="noreferrer">
                Terms of Service
                <span className="sr-only"> (opens in new window)</span>
              </a>
            </li>
            <li>
              <a href="http://www.xfinity.com/privacy/policy/" target="_blank" rel="noreferrer">
                Privacy Policy
                <span className="sr-only"> (opens in new window)</span>
              </a>
            </li>
            <li>
              <a href="https://www.xfinity.com/privacy/manage-preference/" target="_blank" rel="noreferrer">
                Do Not Sell My Personal Information
                <span className="sr-only"> (opens in new window)</span>
              </a>
            </li>
            <li>
              <Link to="/sitemap">
                Site Map
              </Link>
            </li>
          </ul>
          <p>Restrictions apply. Not available in all areas. Limited to Xfinity On Campus customers. Pricing subject to change. Standard data charges apply to app download and usage. Check with your carrier. Comcast 2018. All Rights Reserved. HBO® and related channels and service marks are the property of Home Box Office, Inc. STARZ® and related channels and service marks are the property of Starz Entertainment, LLC. Visit starz.com for airdates/times. Counterparty © 2017 MRC II Distribution Company, L.P. All Rights Reserved. Outlander © 2017 Sony Pictures Television, Inc. All Rights Reserved. Power © 2017 Starz Entertainment, LLC. All Rights Reserved.  “Billions”, “The Affair” and “Ray Donovan” ©Showtime Networks Inc. All rights reserved. SHOWTIME is a registered trademark of Showtime Networks Inc., a CBS company.  The Walking Dead © 2017 AMC Film Holdings LLC.  All Rights Reserved.</p>
        </div>
        <div className="cmp-revoke">
          <button className="cmp-revoke-consent">Cookie Preferences</button>
        </div>
    </footer>
  );
}