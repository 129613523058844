import './App.css';
import Authenticate from './components/Authenticate';
import { TermsConditions } from './components/termsConditions/TermsConditions';
import { Channels } from './components/channels/Channels';
import { Footer } from './components/footer/Footer';
import { Header } from './components/header/Header';
import { Help } from './components/help/Help';
import { Home } from './components/home/home';
import { Login } from './components/login/Login';
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import { Upgrade } from './components/upgrade/upgrade';
import { Wifi } from './components/wifi/Wifi';
import { Logout } from './components/Logout';
import { Product } from './components/product/Product';
import { WiFiProduct } from './components/wifiproduct/WifiProduct';
import { RadarCheckout } from './components/radarCheckout/RadarCheckout';
import { jwtDecode } from 'jwt-decode';
// import { Radar } from './components/radar/radar';
import { Account } from './components/account/Account';
import { Checkout } from './components/checkout/Checkout';
import { ErrorPicker } from './components/error/ErrorPicker';
import { ErrorPage } from './components/error/ErrorPage';
import { About } from './components/about/About';
import { SiteMap } from './components/siteMap/SiteMap';
import { Radar } from './components/radar/radar';
import { Picker } from './components/picker/Picker';

function App() {
  // Redirect to HTTPS if the site is accessed via HTTP and hostname is not localhost
  if (window.location.protocol === 'http:' && window.location.hostname !== 'localhost') {
    console.log('Redirecting to HTTPS');
    window.location.href = `https://${window.location.hostname}${window.location.pathname}${window.location.search}`;
    return null; // Return null to prevent rendering the rest of the component
  }

  // Create script element
  const script = document.createElement('script');
  script.src = process.env.REACT_APP_RADAR_SCRIPT_URL as string;
  document.head.appendChild(script);
  const queryParameters = new URLSearchParams(window.location.search);
  
  console.log('window.location.pathname:', window.location.pathname)
  if(window.location.pathname === '/picker'){
    return (
      <Picker />
    )
  }
 else if (window.location.pathname === '/storefrontLogout') {
    console.log('/logout')
    return (
      <Logout />
    )
  } else if (localStorage.getItem('authorizationToken') != null) {
    const authToken = localStorage.getItem('authorizationToken') as string;
    const claims: any = jwtDecode(authToken);
    let partner = claims.partner;
    let isRadar = (claims.radar === undefined) ? true : claims.radar as boolean;
    let termsAccepted = claims["tc-accepted-date"] as boolean;
    console.log('TermsConditionsAccepted:', termsAccepted);
    const xaScript = document.createElement('script');
    xaScript.type = 'module';
    xaScript.crossOrigin = 'anonymous';
    xaScript.src = process.env.REACT_APP_XA_URL as string;
    document.head.appendChild(xaScript);
    return (
      <div className="main-wrapper">
        <Router>
          <Header />
          <Routes>
            {partner.stream && (partner.managedWifi || partner.dpskWifi) ? (
              <>
                <Route path="/home" element={<Home />} />
                <Route path="/" element={<Navigate to="/home" />} />
                <Route path="/login" element={<Navigate to="/home" />} />
                <Route path="/wifi" element={termsAccepted ? <Wifi /> : <TermsConditions />} />
                <Route path="/wifiproduct" element={<WiFiProduct />} />
                {/* <Route path="/channels" element={<Channels />} /> */}
                <Route path="/upgrade" element={termsAccepted ? <Upgrade /> : <TermsConditions />} />
                <Route path="/product" element={<Product />} />
              </>
            ) : partner.stream === false && (partner.managedWifi || partner.dpskWifi) ? (
              <>
                <Route path="/home" element={termsAccepted ? <Wifi /> : <TermsConditions />} />
                <Route path="/wifi" element={termsAccepted ? <Wifi /> : <TermsConditions />} />
                <Route path="/" element={<Navigate to="/wifi" />} />
                <Route path="/login" element={<Navigate to="/wifi" />} />
                <Route path="/wifiproduct" element={<WiFiProduct />} />
              </>
            ) : (
              <>
                <Route path="/home" element={<Home />} />
                <Route path="/" element={<Navigate to="/home" />} />
                <Route path="/login" element={<Navigate to="/home" />} />
                {/* <Route path="/channels" element={<Channels />} /> */}
                <Route path="/upgrade" element={termsAccepted ? <Upgrade /> : <TermsConditions />}/>
                <Route path="/product" element={<Product />} />
              </>
            )}
            <Route path="/account" element= {termsAccepted ? isRadar ? <Radar /> : <Account /> : <TermsConditions />} />
            <Route path="/channels" element= {termsAccepted ? <Channels /> : <TermsConditions />} />
            <Route path="/checkout" element={  termsAccepted ?  isRadar ? <RadarCheckout /> : <Checkout/> : <TermsConditions/>}/>
            <Route path="/terms" element={<TermsConditions />} />
            <Route path="/help" element={<Help />} />
            <Route path='/about' element={<About/>} />
            <Route path="/sitemap" element={<SiteMap />} />
          </Routes>
          <Footer note='my footer' />
        </Router>
      </div>
    );
  } else if ((window.location.pathname === '/jwt' || window.location.pathname === '/sat') && ((queryParameters.has("uuid") && queryParameters.has("uuid_jwt")) || (queryParameters.has("code")))) {
    console.log(window.location.pathname)
    return (
      <div className="main-wrapper">
        <Router>
          <Header />
          <Authenticate />
          <Footer note='my footer' />
        </Router>
      </div>
    )
  } else {
    console.log('else')
    return (
      <div className="main-wrapper">
        <Router>
          <Header />
          <Routes >
            <Route path="/login" element={<Login />} />
            <Route path="/picker" element={<Login />} />
            <Route path="/terms" element={<TermsConditions />} />
            <Route path="*" element={<Navigate to="/login" />} />
            <Route path="/pickererror" element={<ErrorPicker/>} />
            <Route path="/errorPage" element={<ErrorPage/>} />
            <Route path='/about' element={<About/>} />
            <Route path="/sitemap" element={<SiteMap />} />
          </Routes>
          <Footer note='my footer' />
        </Router>
      </div>
    );
  }
}
export default App;